<template>
  <v-row
    class="faq-bar d-flex justify-center align-center"
    v-bind:style="
      isMobile
        ? 'background-image: url(/footer/faq-small.jpg)'
        : 'background-image: url(/cards/faq-banner.png)'
    "
  >
    <div class="d-flex align-center">
      <div
        class="d-flex flex-column justify-center align-center text-center px-16 px-sm-0"
      >
        <h2>{{ $t("footer.faq.title") }}</h2>
        <v-btn
          color="primary"
          to="/faq"
          depressed
          class="white mt-14 mt-sm-5"
          >{{ $t("footer.faq.button") }}</v-btn
        >
      </div>
    </div>
  </v-row>
</template>

<style lang="scss">
.faq-bar {
  height: 218px;
  background-size: cover;
  h2 {
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    color: $white;
  }
  .v-btn {
    background: $white;
    font-size: 12px;
    font-weight: 700;
  }
}
</style>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    }
  }
};
</script>
